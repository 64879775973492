import React, { useState, useEffect } from 'react';
import '../App.css'; // Yolunuz farklı olabilir.

function RotatingWords() {
  const words = ['Strategic', 'Innovative', 'Dynamic', 'Proactive', 'Trusted', 'Visionary'];
  const [index, setIndex] = useState(0);

  useEffect(() => {
    const intervalId = setInterval(() => {
      setIndex((currentIndex) => (currentIndex + 1) % words.length);
    }, 2000);
    return () => clearInterval(intervalId);
  }, []);

  return (
    <span className="text-indigo-600 animated-word" key={index}>
      {words[index]}
    </span>
  );
}

export default RotatingWords;
