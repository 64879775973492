import { useState, useRef } from 'react'
import { Dialog, Disclosure } from '@headlessui/react'
import { Bars3Icon, XMarkIcon } from '@heroicons/react/24/outline'
import { MinusSmallIcon, PlusSmallIcon, BoltIcon, BeakerIcon, ChartBarIcon, RocketLaunchIcon } from '@heroicons/react/24/outline'
import { InlineWidget } from "react-calendly";
import RotatingWords from './RotatingWords';
import SmartSuppChat from '../components/SmartSuppChat'; // Gerekirse yolu ayarlayın


const navigation = [

]

const faqs = [
  {
    question: "What is a growth-sales agency?",
    answer:
      "We combine sales and marketing strategies to boost business growth, focusing on scalable results through data-driven approaches.",
  },
  {
    question: "How does our process work?",
    answer:
      "Our process includes analyzing your current strategies, crafting a tailored growth plan, and implementing it with continuous optimization.",
  },
  {
    question: "What services do we offer?",
    answer:
      "We provide lead generation, sales funnel optimization, digital marketing, CRM solutions, and sales training, customized to each client’s needs. Through a mix of SEO, content marketing, social media, and paid advertising, tailored to attract and engage the right audience.",
  },
  {
    question: "What makes our sales strategy unique?",
    answer:
      "Our use of data analytics for personalized sales messages and integrating the latest sales technologies to enhance conversion rates.",
  },
  {
    question: "Can we customize our services for different industries?",
    answer:
      "Yes, we adapt our strategies to meet the specific challenges and opportunities of various sectors.",
  },
]

const people = [
  {
    name: 'Talip Furkan Doğan',
    role: 'Founder / CEO',
    imageUrl:
      'https://firebasestorage.googleapis.com/v0/b/falcongrowth-c03d9.appspot.com/o/1683802079091.jpeg?alt=media&token=ec83d64f-9d9f-464c-ac25-9df8bbc63d36',
  },
  {
    name: 'Talha Elmalı',
    role: 'Tech Partner',
    imageUrl:
      'https://firebasestorage.googleapis.com/v0/b/falcongrowth-c03d9.appspot.com/o/WhatsApp%20Image%202024-04-16%20at%2023.17.23.jpeg?alt=media&token=8a900027-345a-4da4-99fa-c7643016bf5d',
  },
]


const features = [
  {
    name: 'Strategy',
    description:
      'We create and activate our strategies together with you in the basis of ICP (Ideal Customer Profile).',
    icon: BoltIcon,
  },
  {
    name: 'AI Automation',
    description:
      'We are pretty good with AI Tools, we speed up the processes from your social media content to your sales offers with customized implementations.',
    icon: BeakerIcon,
  },
  {
    name: 'To be sales-oriented',
    description:
      'One of the main focuses of Growth is that it generates a LOT of sales for us. Here we will work on quick wins, use cases and sales scenarios. Personas will be produced.',
    icon: ChartBarIcon,
  },
  {
    name: 'Contents & Account Based Marketing',
    description:
      'We prepare your separate content for each platform and special content for sales champions on a weekly basis. This service includes reddit and meme marketing.',
    icon: RocketLaunchIcon,
  },
]

export default function Home() {
  const [mobileMenuOpen, setMobileMenuOpen] = useState(false);
  const calendlyRef = useRef(null); // Create a reference to the Calendly widget
  const handleMeetClick = () => {
    calendlyRef.current.scrollIntoView({ behavior: 'smooth' });
  };
  const featureRef = useRef(null); // Create a reference to the Calendly widget
  const handleFeatureClick = () => {
    featureRef.current.scrollIntoView({ behavior: 'smooth' });
  };
  

  return (
<>
<SmartSuppChat smartsuppKey="27fe1edf20dcedcd64b0081112ef761a055475aa" />
<div className="bg-white">
      <header className="absolute inset-x-0 top-0 z-50">
        <nav className="flex items-center justify-between p-6 lg:px-8" aria-label="Global">
          <div className="flex lg:flex-1">
            <a href="#" className="-m-1.5 p-1.5">
              <img
                className="h-16 w-auto"
                src='https://firebasestorage.googleapis.com/v0/b/falcongrowth-c03d9.appspot.com/o/Falcon%20Growth%20Agency%20(1).png?alt=media&token=d540a718-2fdb-4f59-b70c-8ac487ee148b'
                alt=""
              />
            </a>
          </div>
        
          <div className="hidden lg:flex lg:gap-x-12">
            {navigation.map((item) => (
              <a key={item.name} href={item.href} className="text-sm font-semibold leading-6 text-gray-900">
                {item.name}
              </a>
            ))}
          </div>
          <div className="hidden lg:flex lg:flex-1 lg:justify-end">
          <button
              onClick={handleMeetClick} // Use onClick handler for scrolling
              className="rounded-md  px-3.5 py-2.5 text-lg font-semibold text-indigo-700 hover:text-indigo-900 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
            >
              Let's Meet!
            </button>
          </div>
        </nav>
        <Dialog as="div" className="lg:hidden" open={mobileMenuOpen} onClose={setMobileMenuOpen}>
          <div className="fixed inset-0 z-50" />
          <Dialog.Panel className="fixed inset-y-0 right-0 z-50 w-full overflow-y-auto bg-white px-6 py-6 sm:max-w-sm sm:ring-1 sm:ring-gray-900/10">
            <div className="flex items-center justify-between">
              <a href="#" className="-m-1.5 p-1.5">
                <span className="sr-only"></span>
                <img
                  className="h-12 w-auto"
                  src="https://firebasestorage.googleapis.com/v0/b/falcongrowth-c03d9.appspot.com/o/Falcon%20Growth%20Agency%20(1).png?alt=media&token=d540a718-2fdb-4f59-b70c-8ac487ee148b"
                  alt=""
                />
              </a>
              <button
                type="button"
                className="-m-2.5 rounded-md p-2.5 text-gray-700"
                onClick={() => setMobileMenuOpen(false)}
              >
                <span className="sr-only">Close menu</span>
                <XMarkIcon className="h-6 w-6" aria-hidden="true" />
              </button>
            </div>
            <div className="mt-6 flow-root">
              <div className="-my-6 divide-y divide-gray-500/10">
                <div className="space-y-2 py-6">
                  {navigation.map((item) => (
                    <a
                      key={item.name}
                      href={item.href}
                      className="-mx-3 block rounded-lg px-3 py-2 text-base font-semibold leading-7 text-gray-900 hover:bg-gray-50"
                    >
                      {item.name}
                    </a>
                  ))}
                </div>
                <div className="py-6">
                  <a
                    href="#"
                    className="-mx-3 block rounded-lg px-3 py-2.5 text-base font-semibold leading-7 text-gray-900 hover:bg-gray-50"
                  >
                    Log in
                  </a>
                </div>
              </div>
            </div>
          </Dialog.Panel>
        </Dialog>
      </header>

      <div className="relative isolate px-6 pt-14 lg:px-8">
        <div
          className="absolute inset-x-0 -top-40 -z-10 transform-gpu overflow-hidden blur-3xl sm:-top-80"
          aria-hidden="true"
        >
          <div
            className="relative left-[calc(50%-11rem)] aspect-[1155/678] w-[36.125rem] -translate-x-1/2 rotate-[30deg] bg-gradient-to-tr from-[#ff80b5] to-[#9089fc] opacity-30 sm:left-[calc(50%-30rem)] sm:w-[72.1875rem]"
            style={{
              clipPath:
                'polygon(74.1% 44.1%, 100% 61.6%, 97.5% 26.9%, 85.5% 0.1%, 80.7% 2%, 72.5% 32.5%, 60.2% 62.4%, 52.4% 68.1%, 47.5% 58.3%, 45.2% 34.5%, 27.5% 76.7%, 0.1% 64.9%, 17.9% 100%, 27.6% 76.8%, 76.1% 97.7%, 74.1% 44.1%)',
            }}
          />
        </div>
        <div className="mx-auto max-w-4xl pt-32 sm:pt-48 lg:pt-56">
          <div className="hidden sm:mb-8 sm:flex sm:justify-center">
          </div>
          <div className="text-center">
            <h1 className="text-4xl font-bold tracking-tight text-gray-900 sm:text-6xl">
            Your <RotatingWords /> Growth-Sales Partner for Maximum ROI
            </h1>
            <p className="mt-6 text-lg leading-8 text-gray-600">
            Maximize your ROI by Account-Based sales strategies with effective growth tactics, driving revenue and sustainable business value.            </p>
            <div className="mt-10 flex items-center justify-center gap-x-6">
            <button
              onClick={handleFeatureClick} // Use onClick handler for scrolling
              className="rounded-md bg-indigo-700 shadow-sm px-3.5 py-2.5 text-sm font-semibold text-white hover:bg-indigo-900 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
            >
              Let's Go!
            </button>

            </div>
          </div>
        </div>
        <div
          className="absolute inset-x-0 top-[calc(100%-13rem)] -z-10 transform-gpu overflow-hidden blur-3xl sm:top-[calc(100%-30rem)]"
          aria-hidden="true"
        >
          <div
            className="relative left-[calc(50%+3rem)] aspect-[1155/678] w-[36.125rem] -translate-x-1/2 bg-gradient-to-tr from-[#ff80b5] to-[#9089fc] opacity-30 sm:left-[calc(50%+36rem)] sm:w-[72.1875rem]"
            style={{
              clipPath:
                'polygon(74.1% 44.1%, 100% 61.6%, 97.5% 26.9%, 85.5% 0.1%, 80.7% 2%, 72.5% 32.5%, 60.2% 62.4%, 52.4% 68.1%, 47.5% 58.3%, 45.2% 34.5%, 27.5% 76.7%, 0.1% 64.9%, 17.9% 100%, 27.6% 76.8%, 76.1% 97.7%, 74.1% 44.1%)',
            }}
          />
        </div>
      </div>
    </div>
     <div className="bg-white pt-32 sm:pt-48 lg:pt-56" ref={featureRef}>
     <div className="mx-auto max-w-7xl px-6 lg:px-8">
       <div className="mx-auto max-w-2xl lg:text-center">
                 <p className="mt-2 text-3xl font-bold tracking-tight text-gray-900 sm:text-4xl">
                 We have to act fast, there is no time! 
         </p>
         <p className="mt-6 text-lg leading-8 text-gray-600">
         We identify the fastest growth strategies for your product or company and implement them with your team
         </p>
       </div>
       <div className="mx-auto mt-16 max-w-2xl sm:mt-20 lg:mt-24 lg:max-w-4xl" >
         <dl className="grid max-w-xl grid-cols-1 gap-x-8 gap-y-10 lg:max-w-none lg:grid-cols-2 lg:gap-y-16">
           {features.map((feature) => (
             <div key={feature.name} className="relative pl-16">
               <dt className="text-base font-semibold leading-7 text-gray-900">
                 <div className="absolute left-0 top-0 flex h-10 w-10 items-center justify-center rounded-lg bg-indigo-600">
                   <feature.icon className="h-6 w-6 text-white" aria-hidden="true" />
                 </div>
                 {feature.name}
               </dt>
               <dd className="mt-2 text-base leading-7 text-gray-600">{feature.description}</dd>
             </div>
           ))}
         </dl>
       </div>
     </div>
   </div>
   <div className="bg-white">
      <div className="mx-auto max-w-7xl px-6 py-24 sm:py-32 lg:px-8 lg:py-40">
        <div className="mx-auto max-w-4xl divide-y divide-gray-900/10">
          <h2 className="text-2xl font-bold leading-10 tracking-tight text-gray-900">Frequently asked questions</h2>
          <dl className="mt-10 space-y-6 divide-y divide-gray-900/10">
            {faqs.map((faq) => (
              <Disclosure as="div" key={faq.question} className="pt-6">
                {({ open }) => (
                  <>
                    <dt>
                      <Disclosure.Button className="flex w-full items-start justify-between text-left text-gray-900">
                        <span className="text-base font-semibold leading-7">{faq.question}</span>
                        <span className="ml-6 flex h-7 items-center">
                          {open ? (
                            <MinusSmallIcon className="h-6 w-6" aria-hidden="true" />
                          ) : (
                            <PlusSmallIcon className="h-6 w-6" aria-hidden="true" />
                          )}
                        </span>
                      </Disclosure.Button>
                    </dt>
                    <Disclosure.Panel as="dd" className="mt-2 pr-12">
                      <p className="text-base leading-7 text-gray-600">{faq.answer}</p>
                    </Disclosure.Panel>
                  </>
                )}
              </Disclosure>
            ))}
          </dl>
        </div>
      </div>
    </div>
    <div className="bg-white py-24 sm:py-32">
      <div className="mx-auto grid max-w-7xl gap-x-8 gap-y-20 px-6 lg:px-8 xl:grid-cols-3">
        <div className="max-w-2xl">
          <h2 className="text-3xl font-bold tracking-tight text-gray-900 sm:text-4xl">Meet our professionals</h2>
          <p className="mt-6 text-lg leading-8 text-gray-600">
          From 0 to 1, we are with you as team member. Not just a <strong>consultant</strong> title.
          </p>
        </div>
        <ul role="list" className="grid gap-x-8 gap-y-12 sm:grid-cols-2 sm:gap-y-16 xl:col-span-2">
          {people.map((person) => (
            <li key={person.name}>
              <div className="flex items-center gap-x-6">
                <img className="h-16 w-16 rounded-full" src={person.imageUrl} alt="" />
                <div>
                  <h3 className="text-base font-semibold leading-7 tracking-tight text-gray-900">{person.name}</h3>
                  <p className="text-sm font-semibold leading-6 text-indigo-600">{person.role}</p>
                </div>
              </div>
            </li>
          ))}
        </ul>
      </div>
    </div>

    <div className="App overflow-hidden" ref={calendlyRef}>
  <InlineWidget url="https://calendly.com/furkan-qg7t/30min?month=2024-04" className="overflow-hidden" />
</div>

<div className='bg-gray-900 mt-64 py-5'>

        <div className="flex md:flex-row flex-col gap-10 justify-between py-4 max-w-screen-xl mx-auto px-6 "
        ><span className="text-gray-500 text-sm">Copyright 2024, Falcon Growth © All Rights Reserved.</span><span className="text-gray-500 text-sm">Designed &amp; Development With Our Love ❤️  <a href="https://elmalitech.com" className="text-red-500">
Elmali Tech.
      </a> </span>    
        </div>
        </div>
    
</>
  )
}
