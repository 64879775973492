// src/components/SmartSuppChat.js
import React, { useEffect } from 'react';
import useScript from '../hooks/useScript'; // Verify the path is correct

const SmartSuppChat = ({ smartsuppKey }) => {
  useScript('https://www.smartsuppchat.com/loader.js?');

  useEffect(() => {
    window._smartsupp = window._smartsupp || {};
    window._smartsupp.key = smartsuppKey;

    if (!window.smartsupp) {
      window.smartsupp = function() {
        window.smartsupp._.push(arguments);
      };
      window.smartsupp._ = [];
    }
  }, [smartsuppKey]);

  return null; // This component does not render anything
};

export default SmartSuppChat;
